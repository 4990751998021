@charset "utf-8";

//
.lr50{
  display: flex;
  flex-direction:row;
  @media #{$size_1000}{
	flex-direction:column;
  }
  .lr50_inner{
    width: 50%;
    @media #{$size_1000}{
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
	}
    
  }
}

.detail_h201{
	@include hiragino;
	@include fontsize(38);
	color:#ED8C7F;
	line-height: 1.4;
	@media #{$size_800}{
		@include fontsize(28);
	}
}

.detail_h201--02{
	color:#F8B95D;
}
.detail_h201--03{
	color:#81B975;
}
.detail_p01{
	@include meirio;
	@include fontsize(14);
	line-height: 2.0;
	overflow: hidden;
}

.detail_h2_title02{
	text-align: center;
	overflow: hidden;
	box-sizing: border-box;
	h2{
		@include lato;
		@include fontsize(40);
		font-weight: bold;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 15px;
		letter-spacing: 0.1em;
		overflow: hidden;
		box-sizing: border-box;
		width: 100%;
		@media #{$size_800}{
			@include fontsize(30);
			margin-bottom: 8px;
		}
	}
	p{
		@include font04;
		@include fontsize(13);
		line-height: 1.2;
		text-align: center;
		letter-spacing: 0.4em;
		overflow: hidden;
		box-sizing: border-box;
		width: 100%;
	}
}

.detail_h2_title02_blog{
	text-align: center;
	span{
		@include lato;
		@include fontsize(40);
		font-weight: bold;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 15px;
		letter-spacing: 0.1em;
		display: block;
		@media #{$size_800}{
			@include fontsize(30);
			margin-bottom: 8px;
		}
	}
	p{
		@include font04;
		@include fontsize(13);
		line-height: 1.2;
		text-align: center;
		letter-spacing: 0.4em;
	}
}


.detail_h2_title02_blog01{
	text-align: center;
	h1{
		@include lato;
		@include fontsize(40);
		font-weight: bold;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 15px;
		letter-spacing: 0.1em;
		display: block;
		@media #{$size_800}{
			@include fontsize(30);
			margin-bottom: 8px;
		}
	}
	p{
		@include font04;
		@include fontsize(13);
		line-height: 1.2;
		text-align: center;
		letter-spacing: 0.4em;
	}
}

.detail_h3_title01{
	line-height: 1.2;
	h3{
		@include lato;
		@include fontsize(28);
		font-weight: bold;
		padding-top: 15px;
		padding-bottom: 15px;

	}
	p{
		@include font04;
		&:first-child{
			@include fontsize(15);

		}
		&:nth-child(3){
			@include font04;
			@include fontsize(11);
			
		}
	}
}

.detail_h3_title01--02{
	line-height: 1.2;
	h3{
		@include lato;
		@include fontsize(30);
		font-weight: bold;
		padding-bottom: 30px;

	}
	p{
		width: 19px;
		margin: 0 auto;
		img{
			width: 100%;
		}
	}
}

.detail_h3_title02{
	h3{
		@include lato;
		@include fontsize(28);
		font-weight: bold;
		line-height: 1.2;
		margin-bottom: 8px;
	}
	p{
		@include meirio;
		@include fontsize(11);
		font-weight: bold;
	}

}


