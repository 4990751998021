@charset "utf-8";
//森で食べる
.glamorous-dining{
	.gd_main{
		background-image: url(/assets/img/glamorous-dining/main_back01.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position:center;
		text-align: center;
		display:flex;
		align-items:center;
		width:100%;
		height:100vh;
		position: relative;
		z-index:0;
		
		@include space15;
		.gd_main_inner{
			max-width: 452px;
			margin: 0 auto;
			height: 48vh;
			position: relative;
			.main_h1_text_out{
				h1{
					max-width: 452px;
					padding-bottom: 31px;
					margin-bottom: 29px;
					position: relative;
					img{
						width: 100%;
					}
					&:before{
						position: absolute;
						content:"";
						display: block;
						width: 116px;
						height: 1px;
						background-color: #fff;
						bottom: 0;
						left: 0;
						right: 0;
						margin:auto;
						transform:scaleX(0);
					}

				}
				span{

				}
				p{
					max-width: 144px;
					margin: 0 auto;
					display: inline-block;
					img{
						width: 100%;
					}

				}
			}
			.scroll{
				position: absolute;
				left: 0;
				right: 0;
				margin:auto;
				bottom: 0;
				text-align: center;
				.scroll_txt{
					width: 67px;
					margin: 0 auto 1px auto;
					img{
						width: 100%;
					}
				}
				.scroll_arrow{
					width: 19px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}

			}
		}
	}

	.gd_box01{
		padding-top: 122px;
		padding-bottom: 109px;
		box-sizing: border-box;
		position: relative;
		z-index:0;
		@media #{$size_800}{
			padding-top: 80px;
			padding-bottom: 80px;
		}
		&:before{
			width:31.7708333333%;
			content:"";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #ee8c7f;
			z-index: -1;
			height: 100%;
			@media #{$size_1000}{
				display: none;
			}

		}
		.lr50{
			max-width: 1400px;
			margin: 0 auto;
			box-sizing: border-box;
			@media #{$size_1400}{
				padding-left: 40px;
				padding-right: 40px;
			}
			@media #{$size_1000}{
				padding-left: 0;
				padding-right: 0;
				
			}
			.lr50_inner{
				display: block;
				@media #{$size_1000}{
					@include space15;
					&:first-child{
						margin-bottom: 30px;
					}
				}
				.detail_slider{
					max-width: 700px;
					margin: 0 auto;
					.slick-dots{
						right: 0;
						width: auto;
						display: inline-block !important;
						bottom:-30px;
						li{
							width: 14px;
							height: 14px;
							margin: 0px 0 0 14px;
							button{
								width: 100%;
								height: 100%;
								padding: 0;
								background-color: #d6d6d6;
								border-radius: 7px;
								&:before{
									display: none;
								}
							}
						}
						.slick-active{
							button{
								background-color: #ee8c7f;
							}
						}
					}
					
				}
				
				.gd_txt01{
					margin-left: 90px;
					max-width: 450px;
					@media #{$size_1400}{
						margin-left: 30px;
					}
					@media #{$size_1000}{
						margin: 25px 0 0 0;
					}
					@media #{$size_800}{
						max-width: 100%;
					}
					.detail_h201{
						margin-bottom: 38px;
						@media #{$size_800}{
							margin-bottom: 20px;
						}
					}
					
				}
				img{
					width: 100%;
				}
				p{
					margin: 0 auto;
				}
				&:nth-child(2){
					max-width: 700px;
					margin: 0 auto;
				}
			}
			.gd_txt01--out{
				display: flex;
				align-items:center;
			}
		}
	}

	.gd_box02{
		background-image: url(/assets/img/glamorous-dining/gl_back02.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position:center;
		padding-top: 88px;
		padding-bottom: 79px;
		@include space15;
		.gd_box02_inner{
			.detail_h2_title02{
				h2{
					color:#fff;
				}
				p{
					color:#fff;
				}
				margin-bottom: 45px;
			}
		}
		.detail_p01{
			color:#fff;
			text-align: center;
			@media #{$size_600}{
				br{
					display: none;
				}
			}
		}
	}

	.gd_box03{
		display:flex;
		flex-direction:row;
		width:100%;
		@media #{$size_800}{
			flex-direction:column;
		}
		.gd_box03_inner01{
			width: 33.333333333333%;
			overflow: hidden;
			@media #{$size_800}{
				width: 100%;
			}

			&:first-child{
					.gd_box03_inner{
					background-image: url(/assets/img/glamorous-dining/gd_back03--01.png);
					}
				}
				&:nth-child(2){
					.gd_box03_inner{
					background-image: url(/assets/img/glamorous-dining/gd_back03--02.png);
					}
				}
				&:nth-child(3){
					.gd_box03_inner{
					background-image: url(/assets/img/glamorous-dining/gd_back03--03.png);
					}
				}
			.gd_box03_inner{
				
				background-size: cover;
				background-repeat: no-repeat;
				background-position:center;
				position: relative;
				z-index: 0;
				height: 480px;
				display: block;
				transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
				&:hover{
					transform: scale(1.07,1.07);
					transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
				}
				
				&:before{
					position: absolute;
					width: 100%;
					height: 100%;
					display: block;
					background-color: #000;
					opacity: 0.8;
					z-index: 1;
					content:"";
					display: block;
					transition: 1.3s cubic-bezier(.165,.84,.44,1);
				}
				&:hover{
					&:before{
						opacity: 0.3 ;
						transition: 1.3s cubic-bezier(.165,.84,.44,1) ;
					}
				}
				a{
					display: block;
					width: 100%;
					height: 100%;
					color:#fff;
					position: absolute;
					z-index: 2;
					text-align: center;
					display: flex;
					align-items:center;
					transition: transform 1.3s cubic-bezier(.165,.84,.44,1);
					.detail_h3_title01{
						display: inline-block;
						margin: 0 auto;
						text-align: center;
						@include space15;
						p{
							&:first-child{

							}
							&:nth-child(3){
								
							}
						}
					}

				}
			}
		}
	}

	.gd_box04{
		background-color: #121318;
		color:#fff;
		@include space15;
		text-align: center;
		@include fontsize(13);
		line-height: 1.4;
		padding-top: 44px;
		padding-bottom: 38px;
		.gd_box04_inner{

		}
	}

	.gd_box05{
		padding-top: 90px;
		padding-bottom: 98px;
		background-color: #fafafa;
		@include space15;
		text-align: center;
		.detail_p01{
			margin: 0 auto 48px auto;
			@media #{$size_700}{
				br{
					display: none;
				}
			}
		}
		.detail_slider--02{
			max-width: 1200px;
			margin: 0 auto;
			.slick-slide{
				img{
					width: 100%;
				}
				position: relative;
				p{
					position: absolute;
					left: 0px;
					bottom: 0px;
					background-color: rgba(102,0,0,0.7);
					color:#fff;
					width: 100%;
					box-sizing: border-box;
					text-align: left;
					padding: 30px 25px 28px 25px;
					@media #{$size_700}{
						position:static;
						background-color: transparent;
						padding: 15px 20px 15px 20px;
						color:#0c0c0c;
					}
				}
			}
			.slick-dots{
				right: 0;
				left:0;
				margin:auto;
				width: auto;
				display: inline-block !important;
				bottom:-30px;
				li{
					width: 14px;
					height: 14px;
					margin: 0px 0 0 14px;
					button{
						width: 100%;
						height: 100%;
						padding: 0;
						background-color: #d6d6d6;
						border-radius: 7px;
						&:before{
							display: none;
						}
					}
				}
				.slick-active{
					button{
						background-color: #ee8c7f;
					}
				}

			}
			.slick-prev, .slick-next {
					 
			    position: absolute;
			    top: 50%;
			    display: block;
			    width: 30px;
			    height: 33px;
			    padding: 0;
			    -webkit-transform: translate(0, -50%);
			    -ms-transform: translate(0, -50%);
			    transform: translate(0, -50%);
			    cursor: pointer;
			    color: transparent;
			    border: none;
			    outline: none;
			    z-index: 1;
			    &:before{
			    	display: none;
			    }
			    @media #{$size_700}{
			    	display: none !important;
			    }

			}
			.slick-prev{
			    background-image: url(/assets/img/glamorous-dining/prev.png);
		    	background-size: 20px 33px;
		    	display: block;
		    	background-repeat: no-repeat;
		    	background-position: top center;
		    	left: 20px;
			}
			.slick-next{
			    background-image: url(/assets/img/glamorous-dining/next.png);
		    	background-size: 20px 33px;
		    	display: block;
		    	background-repeat: no-repeat;
		    	background-position: top center;
		    	right: 20px;
			}
		}
		.detail_h2_title02{
			margin-bottom: 53px;
		}
		figure{
			max-width: 1200px;
			margin: 0 auto;
			img{
				width: 100%;
			}
		}

	}
	

	.gd_box07{
		background-color: #EE8C7F;
		padding-top: 93px;
		padding-bottom: 108px;
		.detail_h2_title02{
			margin-bottom: 81px;
			h2{
				color: #fff;
			}
			p{
				color: #fff;
			}
		}
		
		
		.gd_tel{
			text-align: center;
			@include hiragino;
			@include space15;

			p{
				&:first-child{
					color: #fff;
					@include fontsize(18);
					line-height: 1.2;
					margin-bottom: 29px;
				}
				&:nth-child(3){
					color: #0C0C0C;
					@include fontsize(14);
					@include hiragino;
					line-height: 1.2;
					font-weight: bold;
					span{
						background-color: #0C0C0C;
						color:#fff;
						@include fontsize(12);
						display: inline-block;
						padding:2px 4px 2px 4px;
						margin-left: 4px;
						margin-right: 4px;
					}
				}
			}
			figure{
				max-width: 297px;
				margin: 0 auto 23px auto;
				line-height: 1.2;
				img{
					width: 100%;
				}
			}
		}
	}

	.gd_box08{
		padding-top: 80px;
		padding-bottom: 85px;
		@media #{$size_700}{
			@include space15;
		}
		a{
			display: block;
			border:1px solid #0c0c0c;
			box-sizing: border-box;
			margin: 0 auto;
			max-width: 660px;
			padding-top: 25px;
			padding-bottom: 28px;
			overflow: hidden;
			display: flex;
			flex-direction:row;
			@media #{$size_700}{
				flex-direction:column;
				@include space15;
			}
			@include transition01;
			&:hover{
				@include transition01;
				background-color: #f9d6d1;
				border:solid 1px #f9d6d1;
				.gd08_left{
				border-right: solid 1px #fff;
				}
			}
			.gd08_left{
				width: 403px;
				border-right: solid 1px #d6d6d6;
				display: flex;
				align-items:center;
				@media #{$size_700}{
					width: 100%;
					border-right: none;
				}
				figure{
					max-width: 302px;
					margin: 0 auto;
					img{
						width: 100%;
					}
				}
			}
			.gd08_right{
				display: flex;
				align-items:center;
				text-align: center;
				width: 254px;
				height: 95px;
				@media #{$size_700}{
					width: 100%;
					height: auto;
					padding-top: 15px;
				}
				p{
					color:#0c0c0c;
					text-align: center;
					margin: 0 auto;
				}
			}

		}
	}
}
.gd_box07{
	text-align: center;
	.gd_contact_btn{
		text-align: center;
		margin-bottom: 63px;
		display:inline-block;
		margin: 0 auto 63px auto;
		a{
			display: block;
			width: 660px;
			height: 120px;
			border-radius: 60px;
			background-color: #0c0c0c;
			color:#fff;
			text-align: center;
			margin: 0 auto;
			@include fontsize(18);
			position: relative;
			box-sizing: border-box;
			position: relative;
			@include transition01;
			overflow: hidden;
			br{
				display: none;
			}
			
			@media #{$size_700}{
				width: 300px;
				@include fontsize(13);
				height: 90px;
				br{
					display: block;
				}
			}
			&:before{
				background-image: url(/assets/img/glamorous-dining/contact_icon01.png);
				background-size: 16px 21px;
				width: 16px;
				height: 21px;
				content:"";
				display: block;
				position: absolute;
				top:50px;
				left:91px;
				z-index: 0;
				z-index: 2;
				@media #{$size_700}{
					left:30px;
					top: 35px;
				}
			}
			&:after{
				background-image: url(/assets/img/glamorous-dining/gd_contact_arrow01.png);
				background-size: 12px 19px;
				width: 12px;
				height: 19px;
				content:"";
				display: block;
				position: absolute;
				top:50px;
				right: 64px;
				z-index: 2;
				@media #{$size_700}{
					right:30px;
					top: 37px;
				}

			}
			span{
				position: relative;
				display: block;
				margin: 0 auto;
				width: 100%;
				height:100%;
				display: flex;
				i{
					margin: 0 auto;
					position: absolute;
					z-index: 1;
					left: 0;
					right: 0;
					margin:auto;
					display: block;
					padding-top: 45px;
					@media #{$size_700}{
						padding-top: 25px;
					}
				}
				&:before{
					content: "";
				    height: 660px;
				    width: 660px;
				    background: #fff;
				    position: absolute;
				    top: 50%;
				    left: 50%;
				    margin: -140px 0 0 -330px;
				    transform-origin: center bottom;
				    transform: rotate(-90deg);
				    z-index: 1;

				}
			}
		}
		&:hover{
			a{
				@include transition01;
				color:#0c0c0c;
				&:after{
					background-image: url(/assets/img/glamorous-dining/gd_contact_arrow01_hover.png);
				}
			}
		}
	}
	.gd_contact_btn--02{
		a{
			&:before{
				background-image: url(/assets/img/foret-aventure/icon05.png);
				background-size: 22px 20px;
				width: 22px;
				height: 20px;
			}
		}
	}

	.gd_contact_btn--03{
		a{
			&:before{
				background-image: url(/assets/img/class-vesso/f_bnr_icon01.png);
				background-size: 28px 22px;
				width: 28px;
				height: 22px;
			}
		}
	}
}


.gd_contact_btn_hover{
	a{
		background-color: #fff !important;
		transition-delay: 0.5s !important;
		span{
			i{
				color:#000;

			}
			&:before{
				transform: rotate(0) !important;
				@include transition01;
			}
		}
	}
}
.gd_contact_btn_remove{
	a{
		background-color: #0c0c0c;
		span{
			i{
				color:#fff;

			}
			&:before{
				transform: rotate(-90deg) !important;
				@include transition01;
			}
		}
	}
}

.gd_box06{
	padding-top: 90px;
	padding-bottom: 90px;
	text-align:center;
	.sample_txt{
		@include fontsize(20);
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
.insta_logo{
	width:98px;
	margin: 0 auto;
	padding-top: 60px;
	padding-bottom: 45px;
	@media #{$size_600}{
		width: 100%;
		box-sizing: border-box;
		text-align: center;
	}
	a{
		width: 98px;
		&:hover{
			opacity: 0.8;
		}
		img{
			width: 100%;
			@media #{$size_600}{
				width: 98px;
			}
		}
		@media #{$size_600}{
			width: 250px;
			display: block;
			border: solid 1px #d2d2d2;
			margin: 0 auto;
			padding-top: 14px;
			padding-bottom: 10px;
			border-radius: 4px;
			
		}
	}
	
}
.snap_out{
	max-width: 1020px;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
	position: relative;
	@media #{$size_600}{
		display: none;
	}
	.insta_link01{

		position: absolute;
		display: block;
		width: 100%;
		height: 100%;

	}
}

.snap_out_sp{
	display: none;
	@media #{$size_600}{
		display: block;
		max-width: 1020px;
		margin: 0 auto;
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		position: relative;
	}
	.insta_link01{

		position: absolute;
		display: block;
		width: 100%;
		height: 100%;

	}
}

.media-grid .media-list-item{
	width:33.33333333%;
}

.glamorous-dining{
	.blog_list{
		padding-top: 90px;
		padding-bottom: 90px;
		background-color: #fff9f8;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
	}
}

.class-vesso{
	.blog_list{
		padding-top: 90px;
		padding-bottom: 90px;
		background-color: #f8fff6;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
	}
}

.foret-aventure{
	.blog_list{
		padding-top: 90px;
		padding-bottom: 90px;
		background-color: #fffaf3;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
	}
}


.blog_list--cvpage{
	article{
		
		&:nth-child(odd){
			background-color: #fff !important;
		}
		&:nth-child(even){
			background-color: #e8f7e4 !important;
		}
		
	}

}

.class-vesso{
	.blog_list{
		padding-top: 90px;
		padding-bottom: 90px;
		background-color: #f8fff6;
		.detail_h2_title02{
			margin-bottom: 60px;
		}
	}
}

.blog_list--fapage{
	article{
		
		&:nth-child(odd){
			background-color: #fff !important;
		}
		&:nth-child(even){
			background-color: #fff5e7 !important;
		}
		
	}

}

.blog-list-btn{
	margin-top: 40px;
	a{
		margin: 0 auto;
		width: 280px;
		display: block;
		img{
			width: 100%;
		}
		&:hover{
			opacity: 0.8;
		}
		@media #{$size_800}{
			width: 230px;
			img{
				width: 100%;
			}
		}
	}
}

.blog_list--gdpage{
	article{
		
		&:nth-child(odd){
			background-color: #fff !important;
		}
		&:nth-child(even){
			background-color: #fff3f1 !important;
		}
		
	}

}



