@charset "utf-8";

//黒い画面のフェードアウト
.load{
	position: relative;
	z-index:100;
	top: 0px;
	left: 0px;
	.load_inner{
		position: fixed;
		width: 100%;
		height:100vh;
		background-color: #000;
		top: 0px;
		left: 0px;
	}
}

/*メインイメージ*/
.glamorous-dining,
.foret-aventure,
.class-vesso{
	.gd_main{
		.gd_main_inner{
			.main_h1_text_out{
				h1{
					opacity: 0;
				}
				
				h1.animated{
					opacity: 0;
					animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s forwards;
					&:before{
						transform:scaleX(1) ;
						transition:0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 2s;
					}
					
				}
				p.animated{
					opacity: 0;
					animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 2.5s forwards;
				}
				
			}
			.scroll.animated{
				opacity: 0;
				animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 5s forwards;
			}

			.scroll.animated .scroll_arrow{
				transform:translateY(0);
				animation:scroll_arrow 2.5s 4.5s infinite;
			}
		}
	}
}

.top_new02{
	.gd_main{
		.gd_main_inner{
			.main_h1_text_out{
				h1{
					opacity: 0;
				}
				
				h1.animated{
					opacity: 0;
					animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s forwards;
				}
			}
			.scroll.animated{
				opacity: 0;
				animation:scroll_fade 2s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s forwards;
				display: block;
			}

			.scroll.animated .scroll_arrow{
				transform:translateY(0);
				animation:scroll_arrow 2.5s 2.5s infinite;
			}
		}
	}

}

@keyframes scroll_fade{
  10% {
    opacity: 0;
    transform: translate3d(0, 30%, 0) scale(1.1); }
  50% {
    transform: translate3d(0, -2%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
}


.top_new{
	.gd_main{
		.gd_main_inner{
			.main_h1_text_out{
				h1{
					opacity: 0;
				}
				
				h1.animated{
					opacity: 0;
					animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s forwards;
				}
			}
			.scroll.animated{
				opacity: 0;
				animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s forwards;
			}

			.scroll.animated .scroll_arrow{
				transform:translateY(0);
				animation:scroll_arrow 2.5s 2.5s infinite;
			}
		}
	}

}

@keyframes scroll_arrow {
  from {
    transform:translateY(0);
    opacity: 0;
  }
  50%{
  	opacity: 1;
  }

  to {
  	opacity: 0;
    transform:translateY(30px);
  }
}

//セクション01
.gd_box01{
	&:before{
		transform:translateX(-70%);
		opacity: 0;
	}
	.detail_slider{
		opacity: 0;
	}
	.gd_txt01{
		opacity: 0;
	}
}

.gd_animate01 {
	&:before{
		animation: gd_before 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
	}
	.detail_slider{
		animation:fadeInLeft02 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s forwards;
	}
	.gd_txt01{
		animation:fadeInRight 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s forwards;
	}
}

@keyframes gd_before {
  from {
    transform:translateX(-70%);
	opacity: 0;
  }

  to {
  	transform:translateX(0);
	opacity: 1;
  }
}
.gd_box02 {
	.detail_h2_title02{
		h2{
			opacity: 0;
		}
		p{
			opacity: 0;
		}
	}
	.detail_p01{
		opacity: 0;
	}
}

.gd_animate02 {
	.detail_h2_title02{
		h2{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
		}
		p{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;

		}
	}
	.detail_p01{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s forwards;

	}
}

.gd_box03{
	.gd_box03_inner{
		&:before{
			opacity: 1;
		}
	}
	.gd03_title01{
		opacity: 0;
	}
	.gd03_title02{
		opacity: 0;
	}
	.gd03_title03{
		opacity: 0;
	}
}

.nt_movie_content_animation {
	.detail_h2_title02{
		h2{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
			
		}
		p{

			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;

		}
	}
	.nt_movie_box01{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;

	}
}

.gd_animate03{
	.gd03_title01{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
	}
	.gd03_title02{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s forwards;
	}
	.gd03_title03{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s forwards;
	}
	/*.gd_box03_inner{
		&:before{
			opacity: 0.8 ;
			animation:opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2s  ;
		}
		
	}
*/
}

.gd_box04{
	.gd_box04_inner{
		opacity: 0;
	}

}

.gd_animate03 + .gd_box04{
	.gd_box04_inner{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;
	}

}

@keyframes opacity {
  from {
    opacity: 0.3;
  }
  to {
  	opacity: 0.8;
  }
}


.gd_box05{
	.detail_h2_title02{
		h2{
			opacity: 0;
		}
		p{
			opacity: 0;
		}
	}
	.detail_p01{
		opacity: 0;
	}
	.detail_slider{
		opacity: 0;
	}
}

.gd_animate05{
	.detail_h2_title02{
		h2{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
		}
		p{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;
		}
	}
	.detail_p01{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s forwards;
	}
	.detail_slider{
		animation:fadeInUp_img 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6s forwards;
	}

}

.gd_box03_cv{
	h3{
		opacity: 0;
	}
	p{
		opacity: 0;
	}
}

.gd_animate03--02{
	h3{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
	}
	p{
		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s forwards;
	}

}

.fa_box01{
	.fa01_left{
		opacity: 0;
	}
	.fa01_right{
		opacity: 0;
	}

}

.fa_animate01 {
	.fa01_left{
		animation:fadeInLeft02 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
	}
	.fa01_right{
		animation:fadeInRight 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s forwards;
	}
}

.fa_animate02 {
	.fa01_left{
		animation:fadeInRight 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
	}
	.fa01_right{
		animation:fadeInLeft02 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s forwards;
	}
}

.fa_box02{
	.fa_btn01{
		opacity: 0;
	}
}

.fa_box02_animate02{
	.fa_btn01{
		animation:fadeInUp0202 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
	}
}

.fa_box03{
	.detail_h2_title02{
		opacity: 0;
	}
	.fa_box03_link{
		opacity: 0;
	}
	.fa_btn01--02{
		opacity: 0;
	}

}

.fa_box03_animate{
	.detail_h2_title02{
		animation:fadeInUp02 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
	}
	.fa_box03_link{
		animation:fadeInUp06 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
		&:first-child{
			animation-delay:0.2s;
		}
		&:nth-child(2){
			animation-delay:0.4s;
		}
		&:nth-child(3){
			animation-delay:0.6s;
		}
		&:nth-child(4){
			animation-delay:0.8s;
		}
	}
	.fa_btn01--02{
		animation:fadeInUp0202 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s forwards;
	}

}

//コンセプトアニメーション
.top_new02{
  .nt_content01{
  	overflow: hidden;
  	perspective: 1000px;
    .nt_content01_inner{
    	transform-style: preserve-3d;
    	transform: rotateX(-30deg) rotateY(0deg) translateZ(50px) translateY(200vh) translateX(200vh) scale(0.9);
    	
    	transform-origin: center;
      .detail_h2_title02{
        opacity: 0;
      }
      .ntc01_txt01{
      	opacity: 0;
      }
      .ntc01_txt02{
        opacity: 0;
      }
    }
  }
}


@keyframes ntcontent01 {
  0% {
    transform: rotateX(-30deg) rotateY(0deg) translateZ(50px) scale(0.5);
    opacity: 0;
    
  }60%{
    transform: rotateY(0deg) scale(1);
    
  }100%{
    transform: rotateY(0deg) translateZ(0px) scale(1);
    opacity: 1;
  }
}
.top_new02{
	.nt_content01_animation{
		.nt_content01_inner{
			animation:ntcontent01 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s forwards;
			.detail_h2_title02{
			animation:fadeInUp_concept 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s forwards;
			}
			.ntc01_txt01{
			animation:fadeInUp_concept 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s forwards;
			}
			.ntc01_txt02{
			animation:fadeInUp_concept 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s forwards;
			}
			
		}

	}
}

.top_new02{
  .content02{
  	.detail_h2_title02{
  		h2{
  			opacity: 0;

  		}
  		p{
  			opacity: 0;

  		}
  	}
  	.con02Menu{
  		li{
  			opacity: 0;
  		}
  	}
  }
}

.top_new02{
  .content02_animation{
  	.detail_h2_title02{
  		h2{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
		}
		p{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;
		}
  	}
  	.con02Menu{
  		li{
  			
  			animation:fadeInUp_top02 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
  			&:first-child{
  				animation-delay:0.5s;
  			}
  			&:nth-child(2){
  				animation-delay:0.8s;
  			}
  			&:nth-child(3){
  				animation-delay:1.1s;
  			}
  		}
  	}
  }
}

.top_new02{
  .nt_content03{
  	.detail_h2_title02{
  		h2{
  			opacity: 0;

  		}
  		p{
  			opacity: 0;

  		}
  	}
  	#gMap{
  		opacity: 0;
  	}
  }
}

.top_new02{
  .nt_content03_animation{
  	.detail_h2_title02{
  		h2{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
		}
		p{
			animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;
		}
  	}
  	#gMap{
  		animation:fadeInUp 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s forwards;
  	}
  	
  }
}


#pagetop{
	@include transition01;
	&:hover{
		bottom:100px;
		@include transition01;
	}
	a{
		&:hover{
			opacity: 1 ;
		}
	}
}





